import React from 'react';
import Download from "./Components/Pages/Download";


const Signin = React.lazy(() => import('./Components/Authentication/SignIn/SignIn1'));
const ResetPassword1 = React.lazy(() => import('./Components/Authentication/ResetPassword/ResetPassword1'));
const ChangePassword = React.lazy(() => import('./Components/Authentication/ChangePassword'));

const PrivacyPolicyForOurRights = React.lazy(() => import('./Components/Pages/PrivacyPolicyForOurRights'));
const PrivacyPolicyForWebsite = React.lazy(() => import('./Components/Pages/PrivacyPolicyForWebsite'));
const TermsAndConditionForOurRights = React.lazy(() => import('./Components/Pages/TermsAndConditionForOurRights'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin },
    { path: '/auth/reset-password', exact: true, name: 'Reset Password ', component: ResetPassword1 },
    { path: '/auth/change-password', exact: true, name: 'Change Password ', component: ChangePassword },
    
    { path: '/pages/privacy-policy-for-our-rights', exact: true, name: 'Privacy Policy for Our Rights ', component: PrivacyPolicyForOurRights },
    { path: '/pages/privacy-policy-for-website', exact: true, name: 'Privacy Policy for Website ', component: PrivacyPolicyForWebsite },
    { path: '/pages/terms-and-condition-for-our-rights', exact: true, name: 'Terms and Condition for Our Rights ', component: TermsAndConditionForOurRights },
    { path: '/download', exact: true, name: 'Download', component: Download },
];

export default route;