import React from 'react';

import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import logoDark from "../../assets/images/logo-black.png";
import qrAndroid from "../../assets/images/download/qr_android.png";
import qrIos from "../../assets/images/download/qr_ios.png";


class Download extends React.Component {


    constructor(props) {
        super(props);
        this.state={
            device:'',
            android:"https://play.google.com/store/apps/details?id=com.pegotec.ourrights",
            ios:"https://apps.apple.com/us/app/our-rights/id1538574146",
            url:''
        }



    }
    componentDidMount() {

        const type = this.getMobileOperatingSystem()
        if (type==='android'){
            this.setState({device:"android"})
            this.setState({url:this.state.android})
            window.location.replace(this.state.android)
        }
        if (type==='ios'){
            this.setState({device:"ios"})
            this.setState({url:this.state.ios})
            window.location.replace(this.state.ios)
        }
    }


    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // // Windows Phone must come first because its UA also contains "Android"
        // if (/windows phone/i.test(userAgent)) {
        //     return "Windows Phone";
        // }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return "unknown";
    }

    renderDevice = () => {

        return (
            <>
                <div className="col-md-12 col-lg-6 mt-2">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="fab fa-android text-c-green d-block f-40"></i>
                            <h4 className="m-t-20">Android</h4>
                            <img src={qrAndroid} alt="qrAndroid"/>

                            <p className="m-b-20">Download Now</p>
                            <a href={this.state.android} target="_blank" className="btn btn-success btn-sm btn-round"><i className="fab fa-google-play"></i>&nbsp;Google Play Store</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6 mt-2">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="fab fa-apple text-c-yellow d-block f-40"></i>
                            <h4 className="m-t-20">iOS</h4>
                            <img src={qrIos} alt="qrIos"/>
                            <p className="m-b-20">Download Now</p>
                            <a href={this.state.ios} target="_blank" className="btn btn-warning btn-sm btn-round"><i className="fab fa-app-store"></i>&nbsp;App Store</a>
                        </div>
                    </div>
                </div>

            </>
        )
    }


    render() {

        return (
            <Aux>
                <div className="auth-wrapper">
                    <div className="card" style={{minWidth: "600px"}}>
                        <div className="card-body p-5">
                            <div className="text-center">
                                <img src={logoDark} alt="" className="img-fluid mb-4" style={{width: '300px'}}/>
                                <div className="mb-4 p-4">
                                    <h5>“Our rights” accompanies Cambodian garment worker Nary on her first days in the factory.</h5>
                                    <h5> Through conversations with her co-workers, she learns about her rights and obligations working in the factory and gets an introduction to occupational health and safety. </h5>
                                    <h5>Chapters can be read and listened to in English and Khmer. </h5>
                                </div>


                            </div>
                            <div className="row justify-content-center">

                                {this.renderDevice()}


                            </div>
                        </div>


                    </div>
                </div>

            </Aux>

        )
    }
}


export default Download;